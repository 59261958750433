import _ from 'lodash'
import { v4 as uuid } from 'uuid'
import { create, remove, subscribeAll, update } from './db'
import { functions, httpsCallable } from './firebase'

const collectionName = 'badges'

const badgeCreate = (group, data) => {
  const badgeId = uuid()
  const { badges } = group.metadata

  badges.push({
    ...data,
    id: badgeId
  })

  update(collectionName, group.id, {
    badges
  })
}

const badgeDeepLinkCreate = async (data) => {
  const call = httpsCallable(functions, 'badgeDeepLinkCreate')
  return await call(data)
}

const badgeGroupCreate = async (data) => {
  await create(collectionName, uuid(), data)
}

const badgeGroupDelete = async (groupId) => {
  await remove(collectionName, groupId)
}

const badgeGroupUpdate = async (groupId, data) => {
  await update(collectionName, groupId, data)
}

const badgeUpdate = (group, badgeId, data) => {
  const { badges } = group.metadata

  const index = _.findIndex(badges, { id: badgeId })
  badges[index] = data

  update(collectionName, group.id, {
    badges
  })
}

const badgesUpdate = (group, badges) => {
  update(collectionName, group.id, {
    badges
  })
}

const badgeGroupsSubscribeAll = (mutationCallback) => subscribeAll(collectionName, mutationCallback)

export {
  badgeCreate,
  badgeDeepLinkCreate,
  badgeGroupCreate,
  badgeGroupDelete,
  badgeGroupUpdate,
  badgeUpdate,
  badgesUpdate,
  badgeGroupsSubscribeAll
}

import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { useNavigate } from 'react-router'
import { Badge, Button, Col, Container, Row, Table } from 'reactstrap'
import { subscribeRecentMembers } from '../../../api/members'
import './index.scss'

const MembersComponent = () => {
  const navigate = useNavigate()
  const [members, setMembers] = useState([])
  const [appId, setAppId] = useState('')

  useEffect(() => {
    let unsubscribe = () => {}
    const run = async () => {
      unsubscribe = await subscribeRecentMembers((result) => {
        setMembers(result)
      })
    }

    run()

    return unsubscribe
  }, [])

  return (<Container className="members">
    <Row><h1>Members</h1></Row>
    <Row>
      <h3>Search</h3>
      <Row>
        <Col>
          <input
            className='input-container__input'
            type="text" placeholder="App Id long or short"
            onChange={(e) => setAppId(e.target.value)}
          />
        </Col>
        <Col>
          <Button onClick={() => navigate(`/admin/members/${appId}`)} disabled={appId.length < 7}>View</Button>
        </Col>
      </Row>
    </Row>
    <Row>
      <h3>Recently active users</h3>
      <Table>
        <tbody>
          {members.map((member) => (
            <tr key={member.id}>
              <th onClick={() => navigate(`/admin/members/${member.id}`)} className="member-link">
                {member.metadata.name || member.id}
              </th>
              <td>
                {member.metadata?.lastActivity ? member.metadata.lastActivity.toDate().toISOString() : '-'} | {member.metadata?.lastActivity ? moment(member.metadata?.lastActivity.toDate()).fromNow() : '-'}
              </td>
              <td>
                {member.metadata?.platform && member.metadata?.platform !== 'unknown' &&
                  <Badge color={member.metadata?.platform === 'iOS' ? 'primary' : 'success' }>{member.metadata?.platform}</Badge>
                }
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Row>
  </Container>)
}

export default MembersComponent

import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import moment from 'moment'
import { useNavigate, useParams } from 'react-router'
import { appMemberGet, memberSubscribe, memberUpdate } from '../../../../api/members'
import Loader from '../../../../components/Loader'
import { Button, Col, Row, Table } from 'reactstrap'
import MakePremiumDialog from './make-premium-dialog'
import PremiumBreakdownTable from './premium-breakdown-table'
import { useSelector } from 'react-redux'
import { Timestamp } from 'firebase/firestore'
import { accountGet } from '../../../../api/accounts'
import '../index.scss'
import './index.scss'
import MemberQueueComponent from './queue'
import MemberDeleteComponent from './delete'
import { EditText, EditTextarea } from 'react-edit-text'

const MemberComponent = () => {
  const navigate = useNavigate()
  const user = useSelector((state) => state.userReducers.user)
  const memberId = useParams().memberId.trim().substr(0, 8)
  const [member, setMember] = useState(null)
  const [premium, setPremium] = useState(false)
  const [loading, setLoading] = useState(true)
  const [makePremiumDialog, setMakePremiumDialog] = useState(false)
  const [lastChange, setLastChange] = useState(null)
  const [teams, setTeams] = useState([])
  const [teamPremium, setTeamPremium] = useState(null)
  const [appMemberGetResults, setAppMemberGetResults] = useState(null)

  useEffect(() => {
    const unsubscribe = memberSubscribe(memberId, (memberData) => {
      if (!memberData.metadata.subscriptionChange) {
        memberData.metadata.subscriptionChange = []
      }

      setMember(memberData)
      setLastChange(_.last(memberData.metadata.subscriptionChange))

      setLoading(false)
    })

    return unsubscribe
  }, [memberId])

  useEffect(() => {
    setPremium(null)

    if (lastChange) {
      if (lastChange.expiryDate) {
        if (new Date(lastChange.expiryDate) > new Date()) {
          setPremium(lastChange.productId)
        }
      }
    }

    return () => {}
  }, [member])

  useEffect(() => {
    if (memberId) {
      appMemberGet(memberId).then((result) => {
        setAppMemberGetResults(result)
      })
    }

    return () => {}
  }, [memberId])

  useEffect(() => {
    setTeams([])

    const run = async () => {
      const memberTeams = []
      for (let i = 0; i < member.metadata.accounts.length; i++) {
        const accountId = member.metadata.accounts[i]

        // todo: later get member premium based on his record in teams
        // const accountMember = await accountMemberGetByAppId(accountId, shortAppId, db);

        let account = await accountGet(accountId)

        if (!account.exist) {
          continue
        }

        if (!account.metadata.masterAccount) {
          account = await accountGet(account.metadata.parentAccount)
        }
        memberTeams.push(account)

        if (account && account.metadata.premiumMembers) {
          setTeamPremium({
            active: true,
            expiryDate: 'until cancelled',
            source: account.id,
            productId: 'team',
            actions: []
          })
        }
      }

      setTeams(memberTeams)
    }
    if (member?.metadata?.accounts) {
      run()
    }

    return () => {}
  }, [member])

  const removeManualPremium = () => {
    const subscriptionChange = [
      ...member.metadata.subscriptionChange,
      {
        createdAt: new Timestamp(new Date().getTime() / 1000, 0),
        operation: 'cancel',
        platform: 'admin',
        content: `Cancelled by admin: ${user.id}`
      }
    ]

    memberUpdate(member.id, { subscriptionChange })
  }

  return (<div key={memberId} className="member-component">
    {member?.metadata &&
    <>
      <Row className="member-name"><EditText
          name="name"
          placeholder={memberId}
          defaultValue={member.metadata.name || memberId}
          onSave={({ value }) => {
            memberUpdate(member.id, { name: value })
          }}
        ></EditText>
        </Row>
      <Row><h2>Premium status: {premium && <>Premium</>} {premium || 'Free'}</h2></Row>
      {loading && <Loader></Loader>}
      {!loading && (
        <>
          <Row>
            <Col>
              <p>Joined: {member.metadata?.createdAt ? moment(member.metadata?.createdAt?.toDate()).fromNow() : '-'}</p>
              <p>Last active: {member.metadata?.lastActivity ? moment(member.metadata?.lastActivity?.toDate()).fromNow() : '-'}</p>
              <p>Full App Id: {member.metadata.appId}</p>
              {member.metadata.referredBy &&
                <p onClick={() => navigate(`/admin/members/${member.metadata.referredBy}`)} className="member-link">Referred by: {member.metadata.referredBy}</p>
              }
              <p>Referral link: {member.metadata.referralLink}</p>
              {member.metadata?.referrals?.length > 0 &&
                <>
                  <p>Referrals</p>
                  <ul>
                    {member.metadata.referrals.map(r => (
                      <li
                      key={`referral-${r.appId}`}
                      onClick={() => navigate(`/admin/members/${r.appId}`)} className="member-link"
                      >{r.appId}</li>
                    ))}
                  </ul>
                </>
              }
              <p>Member of teams: {member.metadata?.accounts?.length > 0 ? member.metadata.accounts.join(', ') : '-'}</p>
            </Col>
            <Col>
              <PremiumBreakdownTable
                lastChange={lastChange}
                teams={teams}
                teamPremium={teamPremium}
                premium={member.metadata.premium}
              ></PremiumBreakdownTable>
            </Col>
          </Row>
          <Row>
            <h3>Note</h3>
            <EditTextarea
              name="note"
              placeholder="Note (click to add a note)"
              defaultValue={member.metadata.note}
              onSave={({ value }) => {
                memberUpdate(member.id, { note: value })
              }}
              rows="auto"
            ></EditTextarea>
          </Row>
          <Row>
            <h3>Subscription history</h3>
            {lastChange &&
              <Table>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Operation</th>
                    <th>Plan</th>
                    <th>Expiry Date</th>
                    <th>Platform</th>
                    <th>Extra Information</th>
                  </tr>
                </thead>
                <tbody>
                  {member.metadata.subscriptionChange.map((change, index) => (
                    <tr key={`change-${index}`}>
                      <td>{change.createdAt ? change.createdAt?.toDate().toISOString() : '-'}</td>
                      <td>{change.operation}</td>
                      <td>{change.productId}</td>
                      <td>{change.expiryDate}</td>
                      <td>{change.platform}</td>
                      <td>{change.content}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            }

            {!premium &&
              <>
                <Button onClick={() => setMakePremiumDialog(true)}>Make Premium</Button>
                <MakePremiumDialog
                  isOpen={makePremiumDialog}
                  member={member}
                  toggle={() => setMakePremiumDialog(false)}>
                </MakePremiumDialog>
              </>
            }

            {premium &&
              <Button onClick={() => removeManualPremium()}>Downgrade manually</Button>
            }
          </Row>
            <MemberQueueComponent
              appId={member.metadata.appId}
            ></MemberQueueComponent>
          <MemberDeleteComponent
            member={member}
          ></MemberDeleteComponent>
          <Row>
            <h3>appMemberGet function result</h3>
            <pre>{JSON.stringify(appMemberGetResults, null, 2)}</pre>
          </Row>
        </>
      )}

      </>
      }
    </div>)
}

export default MemberComponent

import React, { memo, useEffect, useState } from 'react'
import _ from 'lodash'
import { v4 as uuid } from 'uuid'
import { Badge, Button, Col, Container, FormGroup, Input, Label, Row, Table } from 'reactstrap'
import { ReactComponent as PlusIcon } from '../../../images/plus.svg'
import Loader from '../../../components/Loader'

import CustomerEditModal from './modals/customer-edit-modal'
import '../../../styles/base/table-list.scss'
import ActionsMenu from '../../../components/actions-menu'
import CustomerDeleteModal from './modals/delete-confirmation'
import CustomerUnDeleteModal from './modals/undelete-confirmation'
import {
  accountMasterAccountsSubscribe, accountSoftDelete
} from '../../../api/accounts'
import { Link, useNavigate } from 'react-router-dom'

const Customers = memo(() => {
  const navigate = useNavigate()
  const [actionsButtonVisible, setActionsButtonVisible] = useState(false)
  const [isCustomerDeleteModalOpen, setIsCustomerDeleteModalOpen] = useState(false)
  const [isCustomerUnDeleteModalOpen, setIsCustomerUnDeleteModalOpen] = useState(false)
  const [isCustomerModalOpen, setIsCustomerModalOpen] = useState(false)
  const [accounts, setAccounts] = useState(null)
  const [loading, setLoading] = useState(true)
  const [checkboxes, setCheckboxes] = useState({})
  const [sequence, setSequence] = useState(uuid())
  const [showDeleted, setShowDeleted] = useState(false)

  useEffect(() => {
    const unsubscribe = accountMasterAccountsSubscribe((accounts) => {
      const filteredAccounts = _.filter(accounts, account => {
        if (showDeleted) {
          return true
        }

        return !account.metadata.deleted
      })

      setAccounts(_.slice(_.reverse(_.orderBy(filteredAccounts, a => {
        let deleted = 0

        if (a.metadata.deleted) {
          deleted = 1000 * (new Date(a.metadata.deleted)).getTime()
        }

        return a.metadata.createdAt.toDate().getTime() + deleted
      })), 0, 100))
      setLoading(false)
    })

    return unsubscribe
  }, [showDeleted])

  useEffect(() => {
    let visible = false
    _.each(checkboxes, value => {
      if (value) {
        visible = true
      }

      setActionsButtonVisible(visible)
    })
  }, [checkboxes])

  const newCustomer = (customerId) => {
    setIsCustomerModalOpen(true)
  }

  const customerDeleteModalClose = () => {
    setIsCustomerDeleteModalOpen(false)
    togglCheckboxes(false)
  }

  const customerUnDeleteModalClose = () => {
    setIsCustomerUnDeleteModalOpen(false)
    togglCheckboxes(false)
  }

  const accountDelete = async (accountId, deleted) => {
    return accountSoftDelete({ accountId, deleted })
  }

  const executeDeletionProcess = async (deleted) => {
    const accountsToDelete = []

    _.each(checkboxes, (value, key) => {
      if (value) {
        accountsToDelete.push(key)
      }
    })

    for (let i = 0; i <= accountsToDelete.length; i++) {
      await accountDelete(accountsToDelete[i], deleted)
    }

    setIsCustomerDeleteModalOpen(false)
    setIsCustomerUnDeleteModalOpen(false)
  }

  const startDeletionProcess = () => {
    setIsCustomerDeleteModalOpen(true)
  }

  const startUnDeletionProcess = () => {
    setIsCustomerUnDeleteModalOpen(true)
  }

  const togglCheckboxes = (checked) => {
    const newCheckboxes = {}

    _.each(accounts, account => {
      newCheckboxes[account.id] = checked
    })

    setCheckboxes(newCheckboxes)
  }

  const togglCheckbox = (id, checked) => {
    const newCheckboxes = Object.assign(checkboxes, {})
    checkboxes[id] = checked
    setSequence(uuid())
    setCheckboxes({ ...newCheckboxes })
  }

  return (
    <Container>
      <Row className='page-header'>
        <Col xs="3">
          <h3 className='page-header__title'>Customers</h3>
        </Col>

        <Col xs="2">
          {actionsButtonVisible &&
            <ActionsMenu
              onDelete={() => startDeletionProcess()}
              onUnDelete={() => startUnDeletionProcess()}
            />
          }
        </Col>
        <Col xs="1"></Col>
        <Col xs="2">
          <Button onClick={() => newCustomer()}><PlusIcon/> Add Customer</Button>
        </Col>
        <Col xs="3">
          <FormGroup
            switch
            inline
          >
            <Input
              type="switch"
              checked={showDeleted}
              onChange={() => setShowDeleted(!showDeleted)}
            />
            <Label check>
              Show deleted
            </Label>
          </FormGroup>
        </Col>
      </Row>
      {!loading &&
        <Row>
         <Table bordered className="table-list">
          <thead>
              <tr>
                <th className="checkbox">
                  <Input type="checkbox" onClick={(e) => togglCheckboxes(e.target.checked)} />

                </th>
                <th className="no-border-right">

                  Name
                </th>
                <th>
                  Date added
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {accounts?.map((account, index) => <tr key={`${account.id}-${sequence}`}>
                <th scope="row" className="checkbox">
                  <Input
                    type="checkbox"
                    name={`account-${account.id}`}
                    onChange={(e) => togglCheckbox(account.id, e.target.checked)}
                    checked={!!checkboxes[account.id]}
                  />
                </th>
                <td className="no-border-right" onClick={() => navigate(`/admin/customer/${account.id}`)}>
                  <Link to={`/admin/customer/${account.id}`}>
                    {account.metadata.customerName || account.id}
                  </Link>
                </td>
                <td>
                  {account.metadata.createdAt?.toDate()?.toISOString().replace('T', ' ').replace('Z', '').substring(0, 19)}
                </td>
                <td>
                  {!account.metadata.subaccounts &&
                    <>! Subaccounts issue</>
                  }
                  {account.metadata.deleted &&
                    <>
                      <Badge>Deleted on {account.metadata.deleted.toDate().toISOString()}</Badge>&nbsp;
                    </>
                  }
                  {!account.metadata.billing &&
                    <Badge color="primary">Free</Badge>
                  }
                  {account.metadata.billing &&
                    <>
                      <Badge color="success">{account.metadata.billing}</Badge>
                      &nbsp;
                    </>
                  }
                  {account.metadata.premiumMembers &&
                    <>
                      <Badge color="danger">Premium members</Badge>
                      &nbsp;
                    </>
                  }
                </td>
              </tr>)}
            </tbody>
          </Table>
        </Row>
      }
      {loading &&
        <Row className="center">
          <Loader></Loader>
        </Row>
      }
      {isCustomerModalOpen &&
        <CustomerEditModal
          isOpen={isCustomerModalOpen}
          toggle={() => setIsCustomerModalOpen(false)}
          onSubmit={() => setIsCustomerModalOpen(false)}
        />
      }
      {isCustomerDeleteModalOpen &&
        <CustomerDeleteModal
          customers={_.filter(accounts, a => !!checkboxes[a.id])}
          isOpen={true}
          toggle={customerDeleteModalClose}
          onSubmit={() => executeDeletionProcess(true)}
        />
      }
      {isCustomerUnDeleteModalOpen &&
        <CustomerUnDeleteModal
          customers={_.filter(accounts, a => !!checkboxes[a.id])}
          isOpen={true}
          toggle={customerUnDeleteModalClose}
          onSubmit={() => executeDeletionProcess(false)}
        />
      }
    </Container>)
})

Customers.displayName = 'Customers'

export default Customers

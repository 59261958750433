import React, { useEffect, useState } from 'react'
import { Row, Table } from 'reactstrap'
import Loader from '../../../../../components/Loader'
import { subscribeMemberQueue } from '../../../../../api/queue'
import { useNavigate } from 'react-router'

export default function MemberQueueList ({ appId }) {
  const navigate = useNavigate()
  const [queueItems, setQueueItems] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (appId) {
      console.log(`Queue appId: ${appId}`)
      subscribeMemberQueue(appId, (queueMemberData) => {
        setQueueItems(queueMemberData)
        setLoading(false)
      })
    }
  }, [appId])

  return (
    <Row>
      {loading &&
        <Loader></Loader>
      }
      {!loading && queueItems.length > 0 && (
        <Table>
          <thead>
            <tr>
              <th>Id</th>
              <th>Controller</th>
              <th>executeAt</th>
            </tr>
          </thead>
          <tbody>
            {queueItems.map((item, index) => (
              <tr key={`item-${index}`} onClick={() => navigate(`/admin/queue/${item.id}`)}>
                <td>{item.id}</td>
                <td>{item.metadata.controller}</td>
                <td>{item.metadata.executeAt ? item.metadata.executeAt.toDate().toISOString() : '-'}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
      {!loading && queueItems.length === 0 && (
        <p>No upcoming queue items.</p>
      )}
    </Row>
  )
}

import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import {
  Col, ModalBody, ModalFooter, ModalHeader, Row
} from 'reactstrap'
import DropZone from '../../../drop-zone'
import Modal from '../../../Modal/index'
import Input from '../../../Input/index2'
import Select from '../../../Select'
import { getImageUrl } from '../../../../utils/get-image-url'
import '../../../Input/index.scss'
import './index.scss'
import { badgeDeepLinkCreate } from '../../../../api/badges'
import Button from '../../../button'

const booleanOptions = [{
  name: 'Yes',
  value: true
},
{
  name: 'No',
  value: false
}
]

const getTitle = (badge) => {
  if (!badge) {
    return 'Add badge'
  }

  return badge.title
}

const toBoolean = (value) => {
  if (value === true) {
    return true
  }

  if (value === 'true') {
    return true
  }

  return false
}

const BadgeModal = ({
  groups,
  groupId,
  isOpen,
  onSubmit,
  badgeId,
  toggle,
  uploadPath,
  linkPath,
  badgeImages
}) => {
  const group = _.find(groups, { id: groupId })
  const badge = _.find(group.metadata.badges, { id: badgeId })
  const [lockSave, setLockSave] = useState(false)
  const [constraint, setConstraint] = useState(badge?.constraint || false)

  const events = [{
    name: 'Audio sessions',
    value: 'audo-sessions'
  },
  {
    name: 'Audio / ambiance player',
    value: 'ambiance-player'
  },
  {
    name: 'Breathing',
    value: 'breathing'
  },
  {
    name: 'Check-ins',
    value: 'checkins',
    excludeTypes: ['duration']
  },
  {
    name: 'Chats',
    value: 'chats',
    excludeTypes: ['duration']
  },
  {
    name: 'Goals completed',
    value: 'goals-completed',
    excludeTypes: ['duration']
  },
  {
    name: 'All goals completed',
    value: 'all-goals-completed',
    excludeTypes: ['duration'],
    valueRange: -1
  }]

  const defaultTypeOptions = [{
    value: 'amount',
    name: 'Amount (Number of times)'
  }, {
    value: 'duration',
    name: 'Duration'
  }]

  const [imageUrl, setImageUrl] = useState(null)
  const [isMounted, setIsMounted] = useState(true)
  const [typeOptions, setTypeOptions] = useState(defaultTypeOptions)
  const [event, setEvent] = useState(badge.event)

  const {
    handleSubmit,
    getValues,
    setValue,
    register,
    formState: { errors }
  } = useForm({
    defaultValues: {
      color: badge?.color || '#000000',
      constraint,
      description: badge?.description || '',
      endDate: badge?.endDate || '',
      event: badge?.event || '',
      value: badge?.value || 10,
      xp: badge?.xp || 5,
      image: badge?.image || null,
      link: badge?.link || false,
      points: badge?.points || 10,
      published: badge?.published || false,
      startDate: badge?.startDate || '',
      title: badge?.title || '',
      type: badge?.type || 'amount'
    }
  })

  useEffect(() => {
    if (getValues().image) {
      getImageUrl(getValues().image).then(image => {
        if (isMounted) {
          setImageUrl(image)
        }
      })

      return () => setIsMounted(false)
    }
  }, [getValues().image])

  useEffect(() => {
    const eventSelected = _.find(events, { value: event })

    if (eventSelected) {
      const excludeTypes = eventSelected.excludeTypes || []
      setTypeOptions(defaultTypeOptions.filter(type => !excludeTypes.includes(type.value)))
    } else {
      setTypeOptions(defaultTypeOptions)
    }
  }, [event])

  const setDominantColor = color => {
    setValue('color', color)
  }

  const onFormSubmit = async () => {
    setLockSave(true)
    const values = getValues()

    values.constraint = toBoolean(values.constraint) || false
    values.points = parseInt(values.points) || 0
    values.value = parseInt(values.value) || 0
    values.xp = parseInt(values.xp) || 0
    values.published = toBoolean(values.published) || false

    if (values.event === 'all-goals-completed') {
      values.value = -1
      values.type = ''
    }

    if (!badge?.link && values.published) {
      const { data } = await badgeDeepLinkCreate({ id: badgeId })
      values.link = data.success ? data.deepLink.shortLink : false
    } else {
      values.link = badge?.link || null
    }

    if (!values.published) {
      values.link = false
    }

    onSubmit(group.id, {
      ...values,
      id: badgeId,
      updatedAt: new Date()
    })
  }

  return (
    <Modal
      title={false}
      toggle={toggle}
      isOpen={isOpen}
    >
      <form className="badge-form" onSubmit={handleSubmit(onFormSubmit)}>
        <Row>
          <Col>
            <ModalHeader>{getTitle(badge)}</ModalHeader>
          </Col>
        </Row>

        {badge?.link &&
          <Row><Col>Deep link: {badge.link}</Col></Row>
        }

        <ModalBody>
        <Row>
          <Col>
            <Input
              name='title'
              label='Title'
              required={() => true}
              placeholder='Enter title'
              register={register}
              errors={errors}
            />
          </Col>
        </Row>
          <Row>
            <Col>
              <Input
                name='description'
                label='Description'
                required={() => true}
                placeholder='Enter description'
                register={register}
                errors={errors}
              />
            </Col>
          </Row>

          <Row>

            <Col>
              <Select
                name='event'
                label='Event'
                required={() => true}
                options={events}
                register={register}
                errors={errors}
                onChange={(e) => setEvent(e.target.value)}
              />
            </Col>

            <Col>
              <Select
                name='type'
                label='Type'
                required={() => true}
                options={typeOptions}
                register={register}
                errors={errors}
                style={{ visibility: event === 'all-goals-completed' ? 'hidden' : 'visible' }}
              />
            </Col>

            <Col>
              <Input
                name='value'
                label='Value'
                required={() => true}
                placeholder={event === 'all-goals-completed' ? 'n/a' : 10}
                register={register}
                errors={errors}
                style={{ visibility: event === 'all-goals-completed' ? 'hidden' : 'visible' }}
              />
            </Col>

            <Col>
              <Input
                name='xp'
                label='XP'
                required={() => true}
                placeholder='5'
                register={register}
                errors={errors}
              />
            </Col>

          </Row>
          <Row className='d-flex gap-4 justify-content-between'>
            <Col>
              <Select
                name='constraint'
                label='Constraint'
                required={false}
                options={booleanOptions}
                register={register}
                errors={errors}
                onChange={(e) => setConstraint(e.target.value === 'true')}
              />

            </Col>
            {constraint &&
              <>
                <Col>
                  <Input
                    type="date"
                    name='startDate'
                    label='Start'
                    required={() => true}
                    placeholder='mm/dd/yyyy'
                    register={register}
                    errors={errors}
                  />
                </Col>
                <Col>
                  <Input
                    type="date"
                    name='endDate'
                    label='End'
                    required={() => true}
                    placeholder='mm/dd/yyyy'
                    register={register}
                    errors={errors}
                    />
                </Col>
              </>
            }
            {!constraint &&
              <>
                <Col></Col>
                <Col></Col>
                <Col></Col>
              </>
            }
          </Row>

          <Row className='d-flex gap-4 justify-content-between'>
            <Col>
              <input type='hidden' {...register('image')} />
              {!badgeImages &&
                <DropZone
                  name='image'
                  label='Image'
                  required={() => true}
                  style={{ width: 'auto' }}
                  errors={errors}
                  path={uploadPath}
                  uploadSuccess={(image) => setValue('image', image)}
                  uploadStart={() => setLockSave(true)}
                  uploadEnd={() => setLockSave(false)}
                  setDominantColor={color => setDominantColor(color)}
                />
              }
              {badgeImages &&
                <div className="badge-images-selection">
                  {badgeImages.map((image, index) => (
                    <div className={`badge-item ${image === imageUrl ? 'badge-item-selected' : ''}`} key={index} onClick={() => {
                      setValue('image', image)
                      setImageUrl(image)
                      setDominantColor('#fff')
                    }}>
                      <img src={image} alt={image} />
                    </div>
                  ))}
                </div>
              }
              <Input
                type='hidden'
                name='color'
                label='Color'
                required={false}
                style={{ width: '340px' }}
                placeholder='Enter color'
                register={register}
                errors={errors}
              />

            </Col>

            <Col>
              {!badgeImages && imageUrl &&
                <img src={imageUrl} alt='overlay' className='group-item__img' />
              }
            </Col>

            <Col></Col>

            <Col>
              <Select
                name='published'
                label='Published'
                required={false}
                options={booleanOptions}
                register={register}
                errors={errors}
                />
              <Input
                type='hidden'
                name='points'
                required={() => false}
                register={register}
                errors={errors}
              />
            </Col>
          </Row>
          {!badgeImages &&
            <Row>
              <Col><p>Images must be dimension 310x310</p></Col>
            </Row>
          }

        </ModalBody>
        <ModalFooter>
          <Button type='submit' disabled={lockSave} text="Save" />
        </ModalFooter>
      </form>
    </Modal>
  )
}

export default BadgeModal
